import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';

import { DialogService } from 'ng2-bootstrap-modal';
import { PopupComponent } from './popup.component';
import { PopupConfig, defaultConfig } from './popup.config';
import { userPopupConfigToken } from './user-config.token';

@Injectable()
export class PopupService {

  config = {} as PopupConfig;

  constructor(
    private dialogService: DialogService,
    @Inject(userPopupConfigToken) userConfig: PopupConfig) {

    this.config = Object.assign({}, defaultConfig, userConfig);
  }

  alert(title: string, message = '', colorVar?: string, agreeText?: string) {

    colorVar = colorVar || this.config.alertColorVar;
    agreeText = agreeText || this.config.alertAgreeText;

    return this.dialogService.addDialog(PopupComponent, {
      type: 'alert',
      title,
      message,
      colorVar,
      agreeText,
      disagreeText: '',
      isAgreeFirst: true
    });
  }

  confirm(title: string, message = '', colorVar?: string,
    agreeText?: string, disagreeText?: string, isAgreeFirst?: boolean
  ): Observable<boolean> {

    colorVar = colorVar || this.config.confirmColorVar;
    agreeText = agreeText || this.config.agreeText;
    disagreeText = disagreeText || this.config.disagreeText;
    isAgreeFirst = isAgreeFirst || this.config.isAgreeFirst;

    return this.dialogService.addDialog(PopupComponent, {
      type: 'confirm',
      title,
      message,
      colorVar,
      agreeText,
      disagreeText,
      isAgreeFirst
    });
  }

  confirmDelete(
    itemName: string, title?: string, message?: string, colorVar?: string,
    agreeText?: string, disagreeText?: string, isAgreeFirst?: boolean
  ): Observable<boolean> {

    title = title || this.config.deleteTitleFunc(itemName);
    message = message || this.config.deleteMessageFunc(itemName);
    colorVar = colorVar || this.config.deleteColorVar;
    agreeText = agreeText || this.config.agreeText;
    disagreeText = disagreeText || this.config.disagreeText;
    isAgreeFirst = isAgreeFirst || this.config.isAgreeFirst;

    return this.dialogService.addDialog(PopupComponent, {
      type: 'confirm',
      title,
      message,
      colorVar,
      agreeText,
      disagreeText,
      isAgreeFirst
    });
  }

  custom(component: any, options: any) {
    return this.dialogService.addDialog(component, options);
  }
}
